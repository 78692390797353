.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  padding: 20px;
}
.modalBody {
  margin: 0 auto;
  /* padding: 30px 100px; */
  /* background: #ffffff; */
  text-align: center;
}
.image {
  image-orientation: 90deg;
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform: rotate(270deg);
}
