.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  padding: 20px;
}
.modalBody {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 100px;
  background: #ffffff;
  text-align: center;
}
.buttonRadius {
  border-radius: 20px;
}

.buttonConfirm {
  cursor: pointer;
  background-color: var(--risk-primary);
  outline: 0;
  border: 0;
  color: #ffffff;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7 !important;
  }
}

.buttonCancel {
  outline: 0;
  border: 1px solid var(--risk-primary);
  color: var(--risk-primary);
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7 !important;
  }
}

.buttonPadding {
  padding: 16px 33px;
}
.logoutText {
  color: #181818;
  font-size: 0.97em;
  font-size: 0.97em;
}
