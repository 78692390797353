@import "../styles/colors";

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./fonts/FontsFree-Net-AvenirLTStd-Book.ttf) format("opentype");
}

* {
  font-family: Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/*table {
  border-collapse: separate;
  border-spacing: 0 0.6em;
  background: none;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}*/

table thead td:first-child {
  /*border-radius: 40px 0 0 40px;*/
}

table thead td:last-child {
  /*border-radius: 0 40px 40px 0;*/
}

.boxRadius {
  border-radius: 20px;
}

.dotFontSize {
  font-size: 0.9em;
}

.active {
  background-color: rgba(0, 153, 34, 0.3);
  color: var(--risk-primary);
}

.onHold {
  background-color: rgba(144, 153, 1, 0.3);
  color: rgba(144, 153, 1, 1);
}
.closed {
  background-color: rgba(196, 196, 196, 0.3);
  color: rgba(196, 196, 196, 1);
}

.paid {
  background-color: rgba(167, 161, 207, 0.3);
  color: rgba(167, 161, 207, 1);
}
.archive {
  background-color: rgba(161, 199, 207, 0.3);
  color: rgba(161, 199, 207, 1);
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.dotActive {
  background-color: var(--risk-primary);
}
.doOnHold {
  background-color: rgba(144, 153, 1, 1);
}

.dotClosed {
  background-color: rgba(196, 196, 196, 1);
}

.dotPaid {
  background-color: rgba(167, 161, 207, 1);
}
.dotArchive {
  background-color: rgba(161, 199, 207, 1);
}

.bcu-footer-left {
  display: none !important;
}

.bcu-footer-right > button:first-child {
  display: none;
}

.bcu-droppable-content {
  flex: 1 0 160px !important;
}

.box-renderer_heightSm__muO9G {
  height: 20vh !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.overall {
  background-color: red !important;
  width: 300px;
  color: white;
}
