:root {
  --gray: #828282;
  --white: #ffffff;
  --danger: red;
  --primary-light: #29aae2;
  --primary-dark: #1991bb;
  --light-gray: #bdbdbd;
  --black: #0b0b0b;
  --gray-light: #4f4f4f;
  --success: rgba(39, 174, 96, 1);
  --warning: rgba(235, 172, 11, 1);
  --risk-pri: #009922;
  --risk-primary: #2e716f;
  --risk-primary-light: #1d5b58;
}

.text-success {
  color: var(--risk-primary) !important;
}
