.container {
  height: 100vh;
  background-color: #ffffff;
  top: 0 !important;
  z-index: 10;
}

.name {
  font-size: 0.9em;
  color: #181818;
  font-weight: 600;
}

.nameGreen {
  font-size: 0.9em;
  color: var(--risk-primary);
  font-weight: 600;
}

.activeBg {
  /*background-color: #d8ecdd;*/
  /*border-radius: 20px;*/
  font-size: 1.1em;
  font-weight: bolder;
}

.button {
  font-weight: 500;
  outline: 0;
  border: 0;
  border-radius: 20px;
  background: none !important;
  color: #ffffff;
  transition: 0.2s;
  font-size: 0.96em;

  &:hover {
    opacity: 0.7;
  }
}

.user {
  color: #ffffff;
}
.userIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
}

.addLogo {
  width: 100%;
  cursor: pointer;
  border: 2px solid rgb(161, 170, 166, 0.4);
  border-radius: 5px;
  color: #a1aaa6;
  font-weight: 400;
  font-size: 1.2em;
}

.wrapper:hover .showButtons {
  display: flex;
}

.borderBottom {
  border-bottom: 1px solid rgba(235, 234, 234, 0.3);
}

.showButtons {
  border-radius: 20px;
  background-color: rgba(158, 157, 157, 0.8);
  display: none;
}

.loginBar {
  height: 18vh;
  bottom: 0px;
  left: 0px;
  background-color: var(--risk-primary-light);
  background-image: url("../../assets/images/loginBg.png");
  background-size: cover;
}
